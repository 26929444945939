:root {
    --link-nonactive-color: #00bc8c;
    --link-hover-color: #007053;
}

html, body {
    height: 100%;
    scrollbar-gutter: stable;
}

body {
    text-rendering: optimizeLegibility!important;
    background-color: rgb(36, 37, 38);
    background-attachment: fixed;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    font-family: 'PT Serif', 'Noto Serif', serif;
}

header {
    margin-top: 2px;
}

#logo {
    max-width: 85%;
    max-height: 190px;
    display: block;
    margin: 0 auto;
    user-select: none;
}

nav {
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    font-family: Xolonium, Helvetica, sans-serif;
    user-select: none;
}

nav > a {
    margin: 0;
    outline: none;
    color: rgba(255, 255, 255, .6);
    border: 1px solid #374a7f;
    padding: .375em .75em;
    border-radius: .5rem;
    user-select: none;
    line-height: 1.5;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: color 140ms ease-in-out,
                background-color 140ms ease-in-out,
                border-color 140ms ease-in-out,
                box-shadow 140ms ease-in-out;
}

nav > a:hover {
    color: #fff;
    background-color: #375a7f;
    text-decoration: none;
}

nav > a:focus {
    color: #fff;
    box-shadow: 0 0 0.35rem rgba(85, 115, 146, 0.7);
    text-decoration: none;
}

nav > a.active {
    color: #fff;
    background-color: #375a7f;
    border-color: transparent;
}

main {
    flex: 1 0 auto;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    width: 100%;
}

.content-main {
    text-align: justify;
    margin: 28px auto;
    max-width: 800px;
    padding-left: 18px;
    padding-right: 18px;
}

footer {
    flex-shrink: 0;
    margin-top: 15px;
    margin-left: 45px;
    margin-right: 45px;
    padding-bottom: 15px;
    text-align: center;
    font-size: .82rem;
}

a {
    text-decoration: none;
    color: var(--link-nonactive-color);
    background-color: transparent;
}

a[download]::before {
    content: '\f56d';
    font-weight: 400;
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    padding-right: 0.4em;
}

a:hover {
    color: var(--link-hover-color);
    text-decoration: underline;
}

abbr {
    cursor: help;
    text-decoration: underline dotted;
}

.irc-server, .irc-room {
    font-family: 'PT Mono', monospace;
    color: #eee;
}

.irc-room {
    border: 1px solid rgba(204, 204, 204, 0.9);
    border-radius: 0.25rem;
    padding: 0.075rem;
    background-color: rgba(204, 204, 204, 0.1);
}

.game-cmd {
    font-family: monospace;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    line-height: 1.5;
}

time {
    text-decoration: dashed underline;
}

ul.demo-list {
    list-style: disc;
    padding-left: 2.5em;
    padding-left: clamp(1em, 5vw, 2.5em);
    text-align: left;
    line-height: 1.5;
}

.demo-info {
    display: inline-block;
}

.grafana-iframe {
    width: 800px;
    height: 320px;
}

.fa-ghost::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "\f6e2";
}

.title-404 {
    font-family: Xolonium, Helvetica, sans-serif;
    font-size: clamp(1.15rem, 5.05vw, 2.65rem);
    text-align: center;
    background-image: linear-gradient(to bottom, #800000, #ecb711);
    background-size: 100%;
    background-clip: text;
    color: transparent;
    margin-top: 0;
    margin-bottom: 5px;
}

.logo-404 {
    font-size: clamp(3.1rem, 18.5vw, 11rem);
    letter-spacing: 0.1em;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    font-family: "Xolonium", Helvetica, sans-serif;
    background-image: linear-gradient(to top, #800000, #ecb711);
    background-size: 200% 200%;
    background-clip: text;
    color: transparent;
    animation: error-colors 3.5s infinite linear;
    filter: drop-shadow(2px 6px 0px rgba(0, 0, 0, 0.3));
    user-select: none;
    margin-top: 50px;
}

.logo-404 > span.fa-ghost {
    display: inline-block;
    background-image: linear-gradient(to top, #800000, #ecb711);
    background-size: 200% 200%;
    background-clip: text;
    color: transparent;
    animation: spooky 2s alternate infinite linear, error-colors 3.5s infinite linear;
    filter: drop-shadow(2px 6px 0px rgba(0, 0, 0, 0.3));
}

.game-over {
    width: min(90%, 620px);
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    user-select: none;
    filter: drop-shadow(2px 6px 0px rgba(0, 0, 0, 0.3));
}

p.error-404-descr {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

@keyframes spooky {
    from {
        transform: translatey(.10em) scaley(.98);
    }

    to {
        transform: translatey(-.10em);
    }
}

@keyframes error-colors {
    0% { background-position: 0% 0%; }
    50% { background-position: 0% 100%; }
    100% { background-position: 0% 0%; }
}

@media screen and (max-width: 840px) {
    .grafana-iframe {
        width: 100%;
        height: 250px;
    }
}

@media screen and (max-width: 550px) {
    nav {
        margin-top: 15px;
    }
    .content-main {
        margin: 15px auto;
    }
}

@font-face {
    font-family: "Xolonium";
    src: url('/fonts/xolonium-regular.eot');
    src:
        url('/fonts/xolonium-regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/xolonium-regular.woff2') format('woff2'),
        url('/fonts/xolonium-regular.woff') format('woff'),
        url('/fonts/xolonium-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.loading-spinner {
    width: 30vmin;
    fill: #5c5d5d;
    margin: 0 auto;
    display: block;
}

.spinner-small-shadow, .spinner-medium-shadow, .spinner-large-shadow {
    fill: rgba(0, 0, 0, 0.6);
}

.spinner-small {
    animation: loading-counter-rotation 2.5s infinite linear;
    transform-origin: 100.136px 225.345px;
}

.spinner-small-shadow {
    animation: loading-counter-rotation 2.5s infinite linear;
    transform-origin: 110.136px 235.345px;
}

.spinner-medium {
    animation: loading-rotation 3.75s infinite linear;
    transform-origin: 254.675px 379.447px;
}

.spinner-medium-shadow {
    animation: loading-rotation 3.75s infinite linear;
    transform-origin: 264.675px 389.447px;
}

.spinner-large {
    animation: loading-counter-rotation 5s infinite linear;
    transform-origin: 461.37px 173.694px;
}

.spinner-large-shadow {
    animation: loading-counter-rotation 5s infinite linear;
    transform-origin: 471.37px 183.694px;
}

.spinner-text {
    margin: 0 auto;
    text-align: center;
    font-family: Xolonium, Helvetica, sans-serif;
    font-size: clamp(1.1rem, 3.1vmin, 2.4rem);
    padding-top: 15px;
}

@keyframes loading-rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
}

@keyframes loading-counter-rotation {
    from {transform: rotate(359deg);}
    to {transform: rotate(0deg);}
}

header {
    transition: all 260ms cubic-bezier(0.34, 1.56, 0.64, 1) 50ms;
    transform: translateY(0);
    opacity: 1;
}

footer {
    transition: opacity 260ms ease-out 50ms;
    opacity: 1;
}

header.loading {
    transform: translateY(-100%);
    opacity: 0.05;
}

footer.loading {
    opacity: 0;
}

details {
    margin-top: 0.3em;
    overflow: hidden;
}

details summary::-webkit-details-marker { display:none; }

details > summary {
    color: #fff;
    cursor: pointer;
    user-select: none;
    display: block;
    position: relative;
    padding: 0.3em;
    padding-left: 1.2em;
    font-size: 1.1rem;
}

details > summary:hover {
    color: #ddd;
    text-decoration: underline;
    text-decoration-style: dotted;
}

details > summary::before {
    content: '';
    border-width: 0.4em;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 0.5em;
    left: 0.2em;
    transform-origin: 0.2em 50%;
    transform: rotate(0deg);
    transition: .25s transform ease;
}

details[open] > summary:before {
    transform: rotate(90deg);
}

.rules ol {
    padding-inline-start: clamp(0.8em, 3.2vw, 2.4em);
    list-style: none;
    counter-reset: rule;
}

.rules li > ol {
    margin: 1em 0;
}

.rules li::before {
    counter-increment: rule;
    content: counters(rule, ".") " ";
    margin-right: 0.5em;
}

.rules li {
    margin-bottom: 0.1em;
}

@media screen and (max-width: 550px) {
    .rules > ol {
        padding-inline-start: 0;
    }
    .rules li {
        margin-bottom: 1em;
    }

    .rules li > ol {
        margin: 1.2em 0;
    }
}


@media (prefers-reduced-motion: reduce) {
    .spinner-small, .spinner-small-shadow,
    .spinner-medium, .spinner-medium-shadow,
    .spinner-large, .spinner-large-shadow {
        animation-name: none;
    }
    header, footer {
        transition-duration: 0s !important;
        transition-delay: 0s !important;
    }
}
